import {
  First_AfterEight,
  First_Amandes,
  First_BananesSechees,
  First_BiscuitsAuChocola,
  First_Bounty,
  First_Bretzel,
  First_Brittle,
  First_Brownie,
  First_BuenoWhite,
  First_Cacahuetes,
  First_Caramel,
  First_ChapelChapeluresDePistaches,
  First_CheeseCake,
  First_FloconsDeNoixDeCoco,
  First_CopeauxDeChocolatBlanc,
  First_CopeauxDeChocolatNoir,
  First_FloconDeMais,
  First_CoupesDeLaitKinder,
  First_Duplo,
  First_Popcorn,
  First_FraisesSechees,
  First_FramboisesSechees,
  First_Framboises,
  First_Hanuta,
  First_HappyHippo,
  First_KellogsSmacks,
  First_KinderRiegel,
  First_Bueno,
  First_KinderCountry,
  First_KinderMaxiKing,
  First_KinderPinguin,
  First_Kitkat,
  First_Knoppers,
  First_LotusCroqueBiscuitsAuLotus,
  First_M_And_M,
  First_Marshmallow,
  First_Milkyway,
  First_MrTom,
  First_MuesliAuChocolat,
  First_Myrtilles,
  First_Noisettes,
  First_Nougatbits,
  First_Oreo,
  First_PaillettesColorees,
  First_PepitesDeChocolat,
  First_PerlesDeCaramelSale,
  First_Raffaello,
  First_Reeses,
  First_Snikers,
  First_Toblerone,
  First_Tuc,
  First_Gaufres,
  First_Yogurette,
  First_Zimtos,
} from "./1-Angle Images/First_angle_images";

import {
  Second_AfterEight,
  Second_Amandes,
  Second_BananesSechees,
  Second_BiscuitsAuChocola,
  Second_Bounty,
  Second_Bretzel,
  Second_Brittle,
  Second_Brownie,
  Second_BuenoWhite,
  Second_Cacahuetes,
  Second_Caramel,
  Second_ChapelChapeluresDePistaches,
  Second_CheeseCake,
  Second_FloconsDeNoixDeCoco,
  Second_CopeauxDeChocolatBlanc,
  Second_CopeauxDeChocolatNoir,
  Second_FloconDeMais,
  Second_CoupesDeLaitKinder,
  Second_Duplo,
  Second_Popcorn,
  Second_FraisesSechees,
  Second_FramboisesSechees,
  Second_Framboises,
  Second_Hanuta,
  Second_HappyHippo,
  Second_KellogsSmacks,
  Second_KinderRiegel,
  Second_Bueno,
  Second_KinderCountry,
  Second_KinderMaxiKing,
  Second_KinderPinguin,
  Second_Kitkat,
  Second_Knoppers,
  Second_LotusCroqueBiscuitsAuLotus,
  Second_M_And_M,
  Second_Marshmallow,
  Second_Milkyway,
  Second_MrTom,
  Second_MuesliAuChocolat,
  Second_Myrtilles,
  Second_Noisettes,
  Second_Nougatbits,
  Second_Oreo,
  Second_PaillettesColorees,
  Second_PepitesDeChocolat,
  Second_PerlesDeCaramelSale,
  Second_Raffaello,
  Second_Reeses,
  Second_Snikers,
  Second_Toblerone,
  Second_Tuc,
  Second_Gaufres,
  Second_Yogurette,
  Second_Zimtos,
} from "./2-Angle Images/Second_angle_images";

import {
  Third_AfterEight,
  Third_Amandes,
  Third_BananesSechees,
  Third_BiscuitsAuChocola,
  Third_Bounty,
  Third_Bretzel,
  Third_Brittle,
  Third_Brownie,
  Third_BuenoWhite,
  Third_Cacahuetes,
  Third_Caramel,
  Third_ChapelChapeluresDePistaches,
  Third_CheeseCake,
  Third_FloconsDeNoixDeCoco,
  Third_CopeauxDeChocolatBlanc,
  Third_CopeauxDeChocolatNoir,
  Third_FloconDeMais,
  Third_CoupesDeLaitKinder,
  Third_Duplo,
  Third_Popcorn,
  Third_FraisesSechees,
  Third_FramboisesSechees,
  Third_Framboises,
  Third_Hanuta,
  Third_HappyHippo,
  Third_KellogsSmacks,
  Third_KinderRiegel,
  Third_Bueno,
  Third_KinderCountry,
  Third_KinderMaxiKing,
  Third_KinderPinguin,
  Third_Kitkat,
  Third_Knoppers,
  Third_LotusCroqueBiscuitsAuLotus,
  Third_M_And_M,
  Third_Marshmallow,
  Third_Milkyway,
  Third_MrTom,
  Third_MuesliAuChocolat,
  Third_Myrtilles,
  Third_Noisettes,
  Third_Nougatbits,
  Third_Oreo,
  Third_PaillettesColorees,
  Third_PepitesDeChocolat,
  Third_PerlesDeCaramelSale,
  Third_Raffaello,
  Third_Reeses,
  Third_Snikers,
  Third_Toblerone,
  Third_Tuc,
  Third_Gaufres,
  Third_Yogurette,
  Third_Zimtos,
} from "./3-Angle Images/Third_angle_images";

import {
  Sidebar_AfterEight,
  Sidebar_Amandes,
  Sidebar_BananesSechees,
  Sidebar_BiscuitsAuChocola,
  Sidebar_Bounty,
  Sidebar_Bretzel,
  Sidebar_Brittle,
  Sidebar_Brownie,
  Sidebar_BuenoWhite,
  Sidebar_Cacahuetes,
  Sidebar_Caramel,
  Sidebar_ChapelChapeluresDePistaches,
  Sidebar_CheeseCake,
  Sidebar_FloconsDeNoixDeCoco,
  Sidebar_CopeauxDeChocolatBlanc,
  Sidebar_CopeauxDeChocolatNoir,
  Sidebar_FloconDeMais,
  Sidebar_CoupesDeLaitKinder,
  Sidebar_Duplo,
  Sidebar_Popcorn,
  Sidebar_FraisesSechees,
  Sidebar_FramboisesSechees,
  Sidebar_Framboises,
  Sidebar_Hanuta,
  Sidebar_HappyHippo,
  Sidebar_KellogsSmacks,
  Sidebar_KinderRiegel,
  Sidebar_Bueno,
  Sidebar_KinderCountry,
  Sidebar_KinderMaxiKing,
  Sidebar_KinderPinguin,
  Sidebar_Kitkat,
  Sidebar_Knoppers,
  Sidebar_LotusCroqueBiscuitsAuLotus,
  Sidebar_M_And_M,
  Sidebar_Marshmallow,
  Sidebar_Milkyway,
  Sidebar_MrTom,
  Sidebar_MuesliAuChocolat,
  Sidebar_Myrtilles,
  Sidebar_Noisettes,
  Sidebar_Nougatbits,
  Sidebar_Oreo,
  Sidebar_PaillettesColorees,
  Sidebar_PepitesDeChocolat,
  Sidebar_PerlesDeCaramelSale,
  Sidebar_Raffaello,
  Sidebar_Reeses,
  Sidebar_Snikers,
  Sidebar_Toblerone,
  Sidebar_Tuc,
  Sidebar_Gaufres,
  Sidebar_Yogurette,
  Sidebar_Zimtos,
} from "./Sidebar Images/Sidebar_images";

export const ToppingsData = [
  {
    name: "After Eight",
    image1: First_AfterEight,
    image2: Second_AfterEight,
    image3: Third_AfterEight,
    price: 0.5,
    logo: Sidebar_AfterEight,
  },
  {
    name: "Amandes",
    image1: First_Amandes,
    image2: Second_Amandes,
    image3: Third_Amandes,
    price: 0.5,
    logo: Sidebar_Amandes,
  },
  {
    name: "Bananes Séchées",
    image1: First_BananesSechees,
    image2: Second_BananesSechees,
    image3: Third_BananesSechees,
    price: 0.5,
    logo: Sidebar_BananesSechees,
  },
  {
    name: "Doppelkeks",
    image1: First_BiscuitsAuChocola,
    image2: Second_BiscuitsAuChocola,
    image3: Third_BiscuitsAuChocola,
    price: 0.5,
    logo: Sidebar_BiscuitsAuChocola,
  },
  {
    name: "Pépites De Chocolat",
    image1: First_PepitesDeChocolat,
    image2: Second_PepitesDeChocolat,
    image3: Third_PepitesDeChocolat,
    price: 0.5,
    logo: Sidebar_PepitesDeChocolat,
  },
  {
    name: "Bounty",
    image1: First_Bounty,
    image2: Second_Bounty,
    image3: Third_Bounty,
    price: 0.5,
    logo: Sidebar_Bounty,
  },
  {
    name: "Bretzel",
    image1: First_Bretzel,
    image2: Second_Bretzel,
    image3: Third_Bretzel,
    price: 0.5,
    logo: Sidebar_Bretzel,
  },
  {
    name: "Brittle",
    image1: First_Brittle,
    image2: Second_Brittle,
    image3: Third_Brittle,
    price: 0.5,
    logo: Sidebar_Brittle,
  },
  {
    name: "Brownie",
    image1: First_Brownie,
    image2: Second_Brownie,
    image3: Third_Brownie,
    price: 0.5,
    logo: Sidebar_Brownie,
  },
  {
    name: "Bueno",
    image1: First_Bueno,
    image2: Second_Bueno,
    image3: Third_Bueno,
    price: 0.5,
    logo: Sidebar_Bueno,
  },
  {
    name: "Bueno white",
    image1: First_BuenoWhite,
    image2: Second_BuenoWhite,
    image3: Third_BuenoWhite,
    price: 0.5,
    logo: Sidebar_BuenoWhite,
  },
  {
    name: "Cacahuètes",
    image1: First_Cacahuetes,
    image2: Second_Cacahuetes,
    image3: Third_Cacahuetes,
    price: 0.5,
    logo: Sidebar_Cacahuetes,
  },
  {
    name: "Caramel",
    image1: First_Caramel,
    image2: Second_Caramel,
    image3: Third_Caramel,
    price: 0.5,
    logo: Sidebar_Caramel,
  },
  {
    name: "Cheescake",
    image1: First_CheeseCake,
    image2: Second_CheeseCake,
    image3: Third_CheeseCake,
    price: 0.5,
    logo: Sidebar_CheeseCake,
  },
  {
    name: "Copeaux De Chocolat blanc",
    image1: First_CopeauxDeChocolatBlanc,
    image2: Second_CopeauxDeChocolatBlanc,
    image3: Third_CopeauxDeChocolatBlanc,
    price: 0.5,
    logo: Sidebar_CopeauxDeChocolatBlanc,
  },
  {
    name: "Copeaux De Chocolat noir",
    image1: First_CopeauxDeChocolatNoir,
    image2: Second_CopeauxDeChocolatNoir,
    image3: Third_CopeauxDeChocolatNoir,
    price: 0.5,
    logo: Sidebar_CopeauxDeChocolatNoir,
  },
  {
    name: "Kinder Country",
    image1: First_KinderCountry,
    image2: Second_KinderCountry,
    image3: Third_KinderCountry,
    price: 0.5,
    logo: Sidebar_KinderCountry,
  },
  {
    name: "Kinder",
    image1: First_CoupesDeLaitKinder,
    image2: Second_CoupesDeLaitKinder,
    image3: Third_CoupesDeLaitKinder,
    price: 0.5,
    logo: Sidebar_CoupesDeLaitKinder,
  },
  {
    name: "Duplo",
    image1: First_Duplo,
    image2: Second_Duplo,
    image3: Third_Duplo,
    price: 0.5,
    logo: Sidebar_Duplo,
  },
  {
    name: "Flocon de maïs",
    image1: First_FloconDeMais,
    image2: Second_FloconDeMais,
    image3: Third_FloconDeMais,
    price: 0.5,
    logo: Sidebar_FloconDeMais,
  },
  {
    name: "Flocons De Noix De Coco",
    image1: First_FloconsDeNoixDeCoco,
    image2: Second_FloconsDeNoixDeCoco,
    image3: Third_FloconsDeNoixDeCoco,
    price: 0.5,
    logo: Sidebar_FloconsDeNoixDeCoco,
  },
  {
    name: "Fraises Séchées",
    image1: First_FraisesSechees,
    image2: Second_FraisesSechees,
    image3: Third_FraisesSechees,
    price: 0.5,
    logo: Sidebar_FraisesSechees,
  },
  {
    name: "Framboises Séchées",
    image1: First_FramboisesSechees,
    image2: Second_FramboisesSechees,
    image3: Third_FramboisesSechees,
    price: 0.5,
    logo: Sidebar_FramboisesSechees,
  },
  {
    name: "Framboises",
    image1: First_Framboises,
    image2: Second_Framboises,
    image3: Third_Framboises,
    price: 0.5,
    logo: Sidebar_Framboises,
  },
  {
    name: "Gaufres",
    image1: First_Gaufres,
    image2: Second_Gaufres,
    image3: Third_Gaufres,
    price: 0.5,
    logo: Sidebar_Gaufres,
  },
  {
    name: "Hanuta",
    image1: First_Hanuta,
    image2: Second_Hanuta,
    image3: Third_Hanuta,
    price: 0.5,
    logo: Sidebar_Hanuta,
  },
  {
    name: "Happy Hippo",
    image1: First_HappyHippo,
    image2: Second_HappyHippo,
    image3: Third_HappyHippo,
    price: 0.5,
    logo: Sidebar_HappyHippo,
  },
  {
    name: "Kellogs Smacks",
    image1: First_KellogsSmacks,
    image2: Second_KellogsSmacks,
    image3: Third_KellogsSmacks,
    price: 0.5,
    logo: Sidebar_KellogsSmacks,
  },
  {
    name: "Kinder Siegel",
    image1: First_KinderRiegel,
    image2: Second_KinderRiegel,
    image3: Third_KinderRiegel,
    price: 0.5,
    logo: Sidebar_KinderRiegel,
  },
  {
    name: "Kinder Maxi King",
    image1: First_KinderMaxiKing,
    image2: Second_KinderMaxiKing,
    image3: Third_KinderMaxiKing,
    price: 0.5,
    logo: Sidebar_KinderMaxiKing,
  },
  {
    name: "Kinder Pinguin",
    image1: First_KinderPinguin,
    image2: Second_KinderPinguin,
    image3: Third_KinderPinguin,
    price: 0.5,
    logo: Sidebar_KinderPinguin,
  },
  {
    name: "Kitkat",
    image1: First_Kitkat,
    image2: Second_Kitkat,
    image3: Third_Kitkat,
    price: 0.5,
    logo: Sidebar_Kitkat,
  },
  {
    name: "Knoppers",
    image1: First_Knoppers,
    image2: Second_Knoppers,
    image3: Third_Knoppers,
    price: 0.5,
    logo: Sidebar_Knoppers,
  },
  {
    name: "Caramel Biscuits",
    image1: First_LotusCroqueBiscuitsAuLotus,
    image2: Second_LotusCroqueBiscuitsAuLotus,
    image3: Third_LotusCroqueBiscuitsAuLotus,
    price: 0.5,
    logo: Sidebar_LotusCroqueBiscuitsAuLotus,
  },
  {
    name: "M&M",
    image1: First_M_And_M,
    image2: Second_M_And_M,
    image3: Third_M_And_M,
    price: 0.5,
    logo: Sidebar_M_And_M,
  },
  {
    name: "Marshmallow",
    image1: First_Marshmallow,
    image2: Second_Marshmallow,
    image3: Third_Marshmallow,
    price: 0.5,
    logo: Sidebar_Marshmallow,
  },
  {
    name: "Pistache",
    image1: First_ChapelChapeluresDePistaches,
    image2: Second_ChapelChapeluresDePistaches,
    image3: Third_ChapelChapeluresDePistaches,
    price: 0.5,
    logo: Sidebar_ChapelChapeluresDePistaches,
  },
  {
    name: "Milky Way",
    image1: First_Milkyway,
    image2: Second_Milkyway,
    image3: Third_Milkyway,
    price: 0.5,
    logo: Sidebar_Milkyway,
  },
  {
    name: "Nougat",
    image1: First_Nougatbits,
    image2: Second_Nougatbits,
    image3: Third_Nougatbits,
    price: 0.5,
    logo: Sidebar_Nougatbits,
  },
  {
    name: "Mr Tom",
    image1: First_MrTom,
    image2: Second_MrTom,
    image3: Third_MrTom,
    price: 0.5,
    logo: Sidebar_MrTom,
  },
  {
    name: "Muesli Au Chocolat",
    image1: First_MuesliAuChocolat,
    image2: Second_MuesliAuChocolat,
    image3: Third_MuesliAuChocolat,
    price: 0.5,
    logo: Sidebar_MuesliAuChocolat,
  },
  {
    name: "Myrtilles",
    image1: First_Myrtilles,
    image2: Second_Myrtilles,
    image3: Third_Myrtilles,
    price: 0.5,
    logo: Sidebar_Myrtilles,
  },
  {
    name: "Noisettes",
    image1: First_Noisettes,
    image2: Second_Noisettes,
    image3: Third_Noisettes,
    price: 0.5,
    logo: Sidebar_Noisettes,
  },
  {
    name: "Oreo",
    image1: First_Oreo,
    image2: Second_Oreo,
    image3: Third_Oreo,
    price: 0.5,
    logo: Sidebar_Oreo,
  },
  {
    name: "Points De Saupoudrer De Sucre",
    image1: First_PaillettesColorees,
    image2: Second_PaillettesColorees,
    image3: Third_PaillettesColorees,
    price: 0.5,
    logo: Sidebar_PaillettesColorees,
  },
  {
    name: "Pépites De Chocolat",
    image1: First_PepitesDeChocolat,
    image2: Second_PepitesDeChocolat,
    image3: Third_PepitesDeChocolat,
    price: 0.5,
    logo: Sidebar_PepitesDeChocolat,
  },
  {
    name: "Caramel Salé",
    image1: First_PerlesDeCaramelSale,
    image2: Second_PerlesDeCaramelSale,
    image3: Third_PerlesDeCaramelSale,
    price: 0.5,
    logo: Sidebar_PerlesDeCaramelSale,
  },
  {
    name: "Popcorn",
    image1: First_Popcorn,
    image2: Second_Popcorn,
    image3: Third_Popcorn,
    price: 0.5,
    logo: Sidebar_Popcorn,
  },
  {
    name: "Raffaello",
    image1: First_Raffaello,
    image2: Second_Raffaello,
    image3: Third_Raffaello,
    price: 0.5,
    logo: Sidebar_Raffaello,
  },
  {
    name: "Reeses",
    image1: First_Reeses,
    image2: Second_Reeses,
    image3: Third_Reeses,
    price: 0.5,
    logo: Sidebar_Reeses,
  },
  {
    name: "Snikers",
    image1: First_Snikers,
    image2: Second_Snikers,
    image3: Third_Snikers,
    price: 0.5,
    logo: Sidebar_Snikers,
  },
  {
    name: "Toblarone",
    image1: First_Toblerone,
    image2: Second_Toblerone,
    image3: Third_Toblerone,
    price: 0.5,
    logo: Sidebar_Toblerone,
  },
  {
    name: "Tuc",
    image1: First_Tuc,
    image2: Second_Tuc,
    image3: Third_Tuc,
    price: 0.5,
    logo: Sidebar_Tuc,
  },
  {
    name: "Yogurette",
    image1: First_Yogurette,
    image2: Second_Yogurette,
    image3: Third_Yogurette,
    price: 0.5,
    logo: Sidebar_Yogurette,
  },
  {
    name: "Zimtos",
    image1: First_Zimtos,
    image2: Second_Zimtos,
    image3: Third_Zimtos,
    price: 0.5,
    logo: Sidebar_Zimtos,
  },
];
