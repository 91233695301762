export const Third_AfterEight = require("../../../images/toppings/3-Angle Donut Images/donut-after eight.webp");

export const Third_Amandes = require("../../../images/toppings/3-Angle Donut Images/donut-amandes.webp");

export const Third_BananesSechees = require("../../../images/toppings/3-Angle Donut Images/donut-bananes-séchées.webp");

export const Third_BiscuitsAuChocola = require("../../../images/toppings/3-Angle Donut Images/donut-biscuits au chocola.webp");

export const Third_Bounty = require("../../../images/toppings/3-Angle Donut Images/donut-bounty.webp");

export const Third_Bretzel = require("../../../images/toppings/3-Angle Donut Images/donut-bretzel.webp");

export const Third_Brittle = require("../../../images/toppings/3-Angle Donut Images/donut-brittle.webp");

export const Third_Brownie = require("../../../images/toppings/3-Angle Donut Images/donut-brownie.webp");

export const Third_BuenoWhite = require("../../../images/toppings/3-Angle Donut Images/donut-bueno_white.webp");

export const Third_Bueno = require("../../../images/toppings/3-Angle Donut Images/donut-bueno.webp");

export const Third_Cacahuetes = require("../../../images/toppings/3-Angle Donut Images/donut-cacahuètes.webp");

export const Third_Caramel = require("../../../images/toppings/3-Angle Donut Images/donut-caramel.webp");

export const Third_ChapelChapeluresDePistaches = require("../../../images/toppings/3-Angle Donut Images/donut-chapel chapelures de pistaches.webp");

export const Third_CheeseCake = require("../../../images/toppings/3-Angle Donut Images/donut-cheesecake.webp");

export const Third_FloconsDeNoixDeCoco = require("../../../images/toppings/3-Angle Donut Images/donut-flocons de noix de coco.webp");

export const Third_CopeauxDeChocolatBlanc = require("../../../images/toppings/3-Angle Donut Images/donut-copeaux de chocolat blanc.webp");

export const Third_CopeauxDeChocolatNoir = require("../../../images/toppings/3-Angle Donut Images/donut-copeaux de chocolat noir.webp");

export const Third_FloconDeMais = require("../../../images/toppings/3-Angle Donut Images/donut-flocon de maïs.webp");

export const Third_CoupesDeLaitKinder = require("../../../images/toppings/3-Angle Donut Images/donut-coupes de lait kinder.webp");

export const Third_Duplo = require("../../../images/toppings/3-Angle Donut Images/donut-duplo.webp");

export const Third_Popcorn = require("../../../images/toppings/3-Angle Donut Images/donut-flocon de maïs.webp");

export const Third_FraisesSechees = require("../../../images/toppings/3-Angle Donut Images/donut-fraises séchées.webp");

export const Third_FramboisesSechees = require("../../../images/toppings/3-Angle Donut Images/donut-framboises séchées.webp");

export const Third_Framboises = require("../../../images/toppings/3-Angle Donut Images/donut-framboises séchées.webp");

export const Third_Hanuta = require("../../../images/toppings/3-Angle Donut Images/donut-hanuta.webp");

export const Third_HappyHippo = require("../../../images/toppings/3-Angle Donut Images/donut-happy_hippo.webp");

export const Third_KellogsSmacks = require("../../../images/toppings/3-Angle Donut Images/donut-kellogs smacks.webp");

export const Third_KinderRiegel = require("../../../images/toppings/3-Angle Donut Images/donut-kinder riegel.webp");

export const Third_KinderCountry = require("../../../images/toppings/3-Angle Donut Images/donut-kinder_country.webp");

export const Third_KinderMaxiKing = require("../../../images/toppings/3-Angle Donut Images/donut-kinder_maxi_king.webp");

export const Third_KinderPinguin = require("../../../images/toppings/3-Angle Donut Images/donut-kinder_pinguin.webp");

export const Third_Kitkat = require("../../../images/toppings/3-Angle Donut Images/donut-kitkat.webp");

export const Third_Knoppers = require("../../../images/toppings/3-Angle Donut Images/donut-knoppers.webp");

export const Third_LotusCroqueBiscuitsAuLotus = require("../../../images/toppings/3-Angle Donut Images/donut-lotus croqué-biscuits au lotus.webp");

export const Third_M_And_M = require("../../../images/toppings/3-Angle Donut Images/donut-M&M.webp");

export const Third_Marshmallow = require("../../../images/toppings/3-Angle Donut Images/donut-marshmallow.webp");

export const Third_Milkyway = require("../../../images/toppings/3-Angle Donut Images/donut-milkyway.webp");

export const Third_MrTom = require("../../../images/toppings/3-Angle Donut Images/donut-mr tom.webp");

export const Third_MuesliAuChocolat = require("../../../images/toppings/3-Angle Donut Images/donut-muesli au chocolat.webp");

export const Third_Myrtilles = require("../../../images/toppings/3-Angle Donut Images/donut-myrtilles.webp");

export const Third_Noisettes = require("../../../images/toppings/3-Angle Donut Images/donut-noisettes.webp");

export const Third_Nougatbits = require("../../../images/toppings/3-Angle Donut Images/donut-morceaux de nougat.webp");

export const Third_Oreo = require("../../../images/toppings/3-Angle Donut Images/donut-oreo.webp");

export const Third_PaillettesColorees = require("../../../images/toppings/3-Angle Donut Images/donut-paillettes colorées.webp");

export const Third_PepitesDeChocolat = require("../../../images/toppings/3-Angle Donut Images/donut-pépites de chocolat.webp");

export const Third_PerlesDeCaramelSale = require("../../../images/toppings/3-Angle Donut Images/donut-perles de caramel salé.webp");

export const Third_Raffaello = require("../../../images/toppings/3-Angle Donut Images/donut-raffaello.webp");

export const Third_Reeses = require("../../../images/toppings/3-Angle Donut Images/donut-reeses.webp");

export const Third_Snikers = require("../../../images/toppings/3-Angle Donut Images/donut-snikers.webp");

export const Third_Toblerone = require("../../../images/toppings/3-Angle Donut Images/donut-toblerone.webp");

export const Third_Tuc = require("../../../images/toppings/3-Angle Donut Images/donut-tuc.webp");

export const Third_Gaufres = require("../../../images/toppings/3-Angle Donut Images/donut-gaufres.webp");

export const Third_Yogurette = require("../../../images/toppings/3-Angle Donut Images/donut-yogurette.webp");

export const Third_Zimtos = require("../../../images/toppings/3-Angle Donut Images/donut-zimtos.webp");
