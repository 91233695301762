export const Second_AfterEight = require("../../../images/toppings/2-Angle Donut Images/donut-after eight.webp");

export const Second_Amandes = require("../../../images/toppings/2-Angle Donut Images/donut-amandes.webp");

export const Second_BananesSechees = require("../../../images/toppings/2-Angle Donut Images/donut-bananes-séchées.webp");

export const Second_BiscuitsAuChocola = require("../../../images/toppings/2-Angle Donut Images/donut-biscuits au chocola.webp");

export const Second_Bounty = require("../../../images/toppings/2-Angle Donut Images/donut-bounty.webp");

export const Second_Bretzel = require("../../../images/toppings/2-Angle Donut Images/donut-bretzel.webp");

export const Second_Brittle = require("../../../images/toppings/2-Angle Donut Images/donut-brittle.webp");

export const Second_Brownie = require("../../../images/toppings/2-Angle Donut Images/donut-brownie.webp");

export const Second_BuenoWhite = require("../../../images/toppings/2-Angle Donut Images/donut-bueno_white.webp");

export const Second_Bueno = require("../../../images/toppings/2-Angle Donut Images/donut-bueno.webp");

export const Second_Cacahuetes = require("../../../images/toppings/2-Angle Donut Images/donut-cacahuètes.webp");

export const Second_Caramel = require("../../../images/toppings/2-Angle Donut Images/donut-caramel.webp");

export const Second_ChapelChapeluresDePistaches = require("../../../images/toppings/2-Angle Donut Images/donut-chapel chapelures de pistaches.webp");

export const Second_CheeseCake = require("../../../images/toppings/2-Angle Donut Images/donut-cheesecake.webp");

export const Second_FloconsDeNoixDeCoco = require("../../../images/toppings/2-Angle Donut Images/donut-flocons de noix de coco.webp");

export const Second_CopeauxDeChocolatBlanc = require("../../../images/toppings/2-Angle Donut Images/donut-copeaux de chocolat blanc.webp");

export const Second_CopeauxDeChocolatNoir = require("../../../images/toppings/2-Angle Donut Images/donut-copeaux de chocolat noir.webp");

export const Second_FloconDeMais = require("../../../images/toppings/2-Angle Donut Images/donut-flocon de maïs.webp");

export const Second_CoupesDeLaitKinder = require("../../../images/toppings/2-Angle Donut Images/donut-coupes de lait kinder.webp");

export const Second_Duplo = require("../../../images/toppings/2-Angle Donut Images/donut-duplo.webp");

export const Second_Popcorn = require("../../../images/toppings/2-Angle Donut Images/donut-flocon de maïs.webp");

export const Second_FraisesSechees = require("../../../images/toppings/2-Angle Donut Images/donut-fraises séchées.webp");

export const Second_FramboisesSechees = require("../../../images/toppings/2-Angle Donut Images/donut-framboises séchées.webp");

export const Second_Framboises = require("../../../images/toppings/2-Angle Donut Images/donut-framboises séchées.webp");

export const Second_Hanuta = require("../../../images/toppings/2-Angle Donut Images/donut-hanuta.webp");

export const Second_HappyHippo = require("../../../images/toppings/2-Angle Donut Images/donut-happy_hippo.webp");

export const Second_KellogsSmacks = require("../../../images/toppings/2-Angle Donut Images/donut-kellogs smacks.webp");

export const Second_KinderRiegel = require("../../../images/toppings/2-Angle Donut Images/donut-kinder riegel.webp");

export const Second_KinderCountry = require("../../../images/toppings/2-Angle Donut Images/donut-kinder_country.webp");

export const Second_KinderMaxiKing = require("../../../images/toppings/2-Angle Donut Images/donut-kinder_maxi_king.webp");

export const Second_KinderPinguin = require("../../../images/toppings/2-Angle Donut Images/donut-kinder_pinguin.webp");

export const Second_Kitkat = require("../../../images/toppings/2-Angle Donut Images/donut-kitkat.webp");

export const Second_Knoppers = require("../../../images/toppings/2-Angle Donut Images/donut-knoppers.webp");

export const Second_LotusCroqueBiscuitsAuLotus = require("../../../images/toppings/2-Angle Donut Images/donut-lotus croqué-biscuits au lotus.webp");

export const Second_M_And_M = require("../../../images/toppings/2-Angle Donut Images/donut-M&M.webp");

export const Second_Marshmallow = require("../../../images/toppings/2-Angle Donut Images/donut-marshmallow.webp");

export const Second_Milkyway = require("../../../images/toppings/2-Angle Donut Images/donut-milkyway.webp");

export const Second_MrTom = require("../../../images/toppings/2-Angle Donut Images/donut-mr tom.webp");

export const Second_MuesliAuChocolat = require("../../../images/toppings/2-Angle Donut Images/donut-muesli au chocolat.webp");

export const Second_Myrtilles = require("../../../images/toppings/2-Angle Donut Images/donut-myrtilles.webp");

export const Second_Noisettes = require("../../../images/toppings/2-Angle Donut Images/donut-noisettes.webp");

export const Second_Nougatbits = require("../../../images/toppings/2-Angle Donut Images/donut-morceaux de nougat.webp");

export const Second_Oreo = require("../../../images/toppings/2-Angle Donut Images/donut-oreo.webp");

export const Second_PaillettesColorees = require("../../../images/toppings/2-Angle Donut Images/donut-paillettes colorées.webp");

export const Second_PepitesDeChocolat = require("../../../images/toppings/2-Angle Donut Images/donut-pépites de chocolat.webp");

export const Second_PerlesDeCaramelSale = require("../../../images/toppings/2-Angle Donut Images/donut-perles de caramel salé.webp");

export const Second_Raffaello = require("../../../images/toppings/2-Angle Donut Images/donut-raffaello.webp");

export const Second_Reeses = require("../../../images/toppings/2-Angle Donut Images/donut-reeses.webp");

export const Second_Snikers = require("../../../images/toppings/2-Angle Donut Images/donut-snikers.webp");

export const Second_Toblerone = require("../../../images/toppings/2-Angle Donut Images/donut-toblerone.webp");

export const Second_Tuc = require("../../../images/toppings/2-Angle Donut Images/donut-tuc.webp");

export const Second_Gaufres = require("../../../images/toppings/2-Angle Donut Images/donut-gaufres.webp");

export const Second_Yogurette = require("../../../images/toppings/2-Angle Donut Images/donut-yogurette.webp");

export const Second_Zimtos = require("../../../images/toppings/2-Angle Donut Images/donut-zimtos.webp");
