export const First_AfterEight = require("../../../images/toppings/1-Angle Donut Images/donut-after eight.webp");

export const First_Amandes = require("../../../images/toppings/1-Angle Donut Images/donut-amandes.webp");

export const First_BananesSechees = require("../../../images/toppings/1-Angle Donut Images/donut-bananes-séchées.webp");

export const First_BiscuitsAuChocola = require("../../../images/toppings/1-Angle Donut Images/donut-biscuits au chocola.webp");

export const First_Bounty = require("../../../images/toppings/1-Angle Donut Images/donut-bounty.webp");

export const First_Bretzel = require("../../../images/toppings/1-Angle Donut Images/donut-bretzel.webp");

export const First_Brittle = require("../../../images/toppings/1-Angle Donut Images/donut-brittle.webp");

export const First_Brownie = require("../../../images/toppings/1-Angle Donut Images/donut-brownie.webp");

export const First_BuenoWhite = require("../../../images/toppings/1-Angle Donut Images/donut-bueno_white.webp");

export const First_Bueno = require("../../../images/toppings/1-Angle Donut Images/donut-bueno.webp");

export const First_Cacahuetes = require("../../../images/toppings/1-Angle Donut Images/donut-cacahuètes.webp");

export const First_Caramel = require("../../../images/toppings/1-Angle Donut Images/donut-caramel.webp");

export const First_ChapelChapeluresDePistaches = require("../../../images/toppings/1-Angle Donut Images/donut-chapel chapelures de pistaches.webp");

export const First_CheeseCake = require("../../../images/toppings/1-Angle Donut Images/donut-cheesecake.webp");

export const First_FloconsDeNoixDeCoco = require("../../../images/toppings/1-Angle Donut Images/donut-flocons de noix de coco.webp");

export const First_CopeauxDeChocolatBlanc = require("../../../images/toppings/1-Angle Donut Images/donut-copeaux de chocolat blanc.webp");

export const First_CopeauxDeChocolatNoir = require("../../../images/toppings/1-Angle Donut Images/donut-copeaux de chocolat noir.webp");

export const First_FloconDeMais = require("../../../images/toppings/1-Angle Donut Images/donut-flocon de maïs.webp");

export const First_CoupesDeLaitKinder = require("../../../images/toppings/1-Angle Donut Images/donut-coupes de lait kinder.webp");

export const First_Duplo = require("../../../images/toppings/1-Angle Donut Images/donut-duplo.webp");

export const First_Popcorn = require("../../../images/toppings/1-Angle Donut Images/donut-flocon de maïs.webp");

export const First_FraisesSechees = require("../../../images/toppings/1-Angle Donut Images/donut-fraises séchées.webp");

export const First_FramboisesSechees = require("../../../images/toppings/1-Angle Donut Images/donut-framboises séchées.webp");

export const First_Framboises = require("../../../images/toppings/1-Angle Donut Images/donut-framboises séchées.webp");

export const First_Hanuta = require("../../../images/toppings/1-Angle Donut Images/donut-hanuta.webp");

export const First_HappyHippo = require("../../../images/toppings/1-Angle Donut Images/donut-happy_hippo.webp");

export const First_KellogsSmacks = require("../../../images/toppings/1-Angle Donut Images/donut-kellogs smacks.webp");

export const First_KinderRiegel = require("../../../images/toppings/1-Angle Donut Images/donut-kinder riegel.webp");

export const First_KinderCountry = require("../../../images/toppings/1-Angle Donut Images/donut-kinder_country.webp");

export const First_KinderMaxiKing = require("../../../images/toppings/1-Angle Donut Images/donut-kinder_maxi_king.webp");

export const First_KinderPinguin = require("../../../images/toppings/1-Angle Donut Images/donut-kinder_pinguin.webp");

export const First_Kitkat = require("../../../images/toppings/1-Angle Donut Images/donut-kitkat.webp");

export const First_Knoppers = require("../../../images/toppings/1-Angle Donut Images/donut-knoppers.webp");

export const First_LotusCroqueBiscuitsAuLotus = require("../../../images/toppings/1-Angle Donut Images/donut-lotus croqué-biscuits au lotus.webp");

export const First_M_And_M = require("../../../images/toppings/1-Angle Donut Images/donut-M&M.webp");

export const First_Marshmallow = require("../../../images/toppings/1-Angle Donut Images/donut-marshmallow.webp");

export const First_Milkyway = require("../../../images/toppings/1-Angle Donut Images/donut-milkyway.webp");

export const First_MrTom = require("../../../images/toppings/1-Angle Donut Images/donut-mr tom.webp");

export const First_MuesliAuChocolat = require("../../../images/toppings/1-Angle Donut Images/donut-muesli au chocolat.webp");

export const First_Myrtilles = require("../../../images/toppings/1-Angle Donut Images/donut-myrtilles.webp");

export const First_Noisettes = require("../../../images/toppings/1-Angle Donut Images/donut-noisettes.webp");

export const First_Nougatbits = require("../../../images/toppings/1-Angle Donut Images/donut-morceaux de nougat.webp");

export const First_Oreo = require("../../../images/toppings/1-Angle Donut Images/donut-oreo.webp");

export const First_PaillettesColorees = require("../../../images/toppings/1-Angle Donut Images/donut-paillettes colorées.webp");

export const First_PepitesDeChocolat = require("../../../images/toppings/1-Angle Donut Images/donut-pépites de chocolat.webp");

export const First_PerlesDeCaramelSale = require("../../../images/toppings/1-Angle Donut Images/donut-perles de caramel salé.webp");

export const First_Raffaello = require("../../../images/toppings/1-Angle Donut Images/donut-raffaello.webp");

export const First_Reeses = require("../../../images/toppings/1-Angle Donut Images/donut-reeses.webp");

export const First_Snikers = require("../../../images/toppings/1-Angle Donut Images/donut-snikers.webp");

export const First_Toblerone = require("../../../images/toppings/1-Angle Donut Images/donut-toblerone.webp");

export const First_Tuc = require("../../../images/toppings/1-Angle Donut Images/donut-tuc.webp");

export const First_Gaufres = require("../../../images/toppings/1-Angle Donut Images/donut-gaufres.webp");

export const First_Yogurette = require("../../../images/toppings/1-Angle Donut Images/donut-yogurette.webp");

export const First_Zimtos = require("../../../images/toppings/1-Angle Donut Images/donut-zimtos.webp");
